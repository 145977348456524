import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Box } from "rebass/styled-components";
import { withTheme } from "styled-components";
const Grid = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <ReactMarkdown source={children} />
    </Box>
  );
};

Grid.propTypes = {
  children: PropTypes.any
};
export default withTheme(Grid);

import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";
import { withTheme } from "styled-components";

const Container = ({ fluid, children, ...props }) => {
  fluid = fluid || false;
  return (
    <Box variant={fluid ? "containerFluid" : "container"} {...props}>
      {children}
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  fluid: PropTypes.bool
};

export default withTheme(Container);
